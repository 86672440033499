import React from "react";

import DirectoryList from "gatsby-theme-notes/src/components/directory-list";
import FileList from "gatsby-theme-notes/src/components/file-list";
import Breadcrumbs from "gatsby-theme-notes/src/components/breadcrumbs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

export default ({ directories, files, breadcrumbs = [], ...props }) => (
  <Layout {...props}>
    <SEO title="Notes" />
    {breadcrumbs.length ? <Breadcrumbs links={breadcrumbs} /> : null}
    <DirectoryList directories={directories} />
    <FileList files={files} />
  </Layout>
);
